import React, { ReactElement, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import loadable from '@loadable/component';

import content from '@Src/config/content';

import { AnimatePresence } from 'framer-motion';

const Home = loadable(() => import('@Containers/Home'));
const Story = loadable(() => import('@Containers/Story'));
const Info = loadable(() => import('@Containers/Info'));
const Thoughts = loadable(() => import('@Containers/Thoughts'));

const Routes = (): ReactElement => {
	const location = useLocation();
	const { stories } = content;

	useEffect(() => {
		// Reset scroll when reloading page
		window.history.scrollRestoration = 'manual';
	}, []);

	return (
		<AnimatePresence exitBeforeEnter onExitComplete={() => window.scrollTo(0, 0)}>
			<Switch location={location} key={location.key}>
				<Route exact path="/">
					<Home />
				</Route>
				<Route
					exact
					path="/berattelser/:id"
					render={({ match }) => <Story story={stories.find((s) => s.id === match.params.id)} />}
				/>
				<Route exact path="/hiv-viktigt">
					<Info />
				</Route>
				<Route exact path="/funderingar">
					<Thoughts />
				</Route>
				<Route path="*" component={Home} />
			</Switch>
		</AnimatePresence>
	);
};
export default Routes;
