import towel from '@Src/assets/aranya/towel.png';
import towel2x from '@Src/assets/aranya/towel2x.png';
import can from '@Src/assets/aranya/can.png';
import can2x from '@Src/assets/aranya/can2x.png';
import locker from '@Src/assets/aranya/locker.png';
import locker2x from '@Src/assets/aranya/locker2x.png';
import phone from '@Src/assets/aranya/phone.png';
import phone2x from '@Src/assets/aranya/phone2x.png';

const aranya = {
	index: 2,
	id: 'aranya',
	name: 'Aranya',
	age: '16',
	cardImages: [
		{
			src: locker,
			src2x: locker2x,
			alt: 'skåp',
			imgStyles: {
				width: '33%',
				top: '4%',
				left: '35%'
			},
			parallaxSpeed: -0.3
		},
		{
			src: can,
			src2x: can2x,
			alt: 'burk',
			imgStyles: {
				width: '23%',
				top: '12%',
				left: '4%'
			},
			parallaxSpeed: -0.45
		},
		{
			src: towel,
			src2x: towel2x,
			alt: 'handduk',
			imgStyles: {
				width: '50%',
				bottom: '3%',
				left: '10%'
			},
			parallaxSpeed: -0.4
		},

		{
			src: phone,
			src2x: phone2x,
			alt: 'telefon',
			imgStyles: {
				width: '55%',
				bottom: '0',
				right: '0'
			},
			parallaxSpeed: -0.4
		}
	],
	heroImages: [
		{
			src: towel,
			src2x: towel2x,
			alt: 'handduk',
			imgStyles: {
				width: '40%',
				height: 'auto',
				bottom: '20px',
				left: '10px'
			},
			parallaxSpeed: -0.4
		},
		{
			src: locker,
			src2x: locker2x,
			alt: 'skåp',
			imgStyles: {
				width: '25%',
				height: 'auto',
				top: '20px',
				left: '40%'
			},
			parallaxSpeed: -0.6
		},
		{
			src: phone,
			src2x: phone2x,
			alt: 'telefon',
			imgStyles: {
				width: '45%',
				height: 'auto',
				bottom: '-40px',
				right: '20px'
			},
			parallaxSpeed: -0.4
		},
		{
			src: can,
			src2x: can2x,
			alt: 'burk',
			imgStyles: {
				width: '50px',
				height: 'auto',
				bottom: '20px',
				right: '55%'
			},
			parallaxSpeed: -0.45
		}
	],
	bgColor: '20, 179, 233',
	body: [
		{
			type: 'text',
			paragraph:
				'Vi hade biologilektion för några veckor sen där läraren pratade om hiv, fast ärligt talat snackade han mest om aids. Det är det enda som de säger, de går inte så djupt liksom. Det var så mycket han sa som var helt fel, men det är ju inte direkt som att jag kan räcka upp handen och rätta honom. Alltså jag fattar inte, dom snackar om källkritik hela tiden sen är dom helt lost själva!'
		},
		{
			type: 'text',
			paragraph:
				'Efter lektionen hade vi idrott, jag och Francine gjorde sällskap från skåpen och var sist in i omklädningsrummet. Jag frågade lite casual vad hon tyckte om lektionen, bara för att se hennes reaktion liksom. Om hon reagerade negativt hade jag skitit i det. Jag frågade om hon visste vad hiv var och hon sa "Ja, det smittas via sex". Och jag ba "Jaså, är det så??", lekte dum liksom för jag ville höra vad hon trodde. Visst, hon hade rätt på vissa grejer, men det var bara 50/50. Hon tog upp telefonen och började googla, sen ropade vår idrottslärare att hela klassen väntade på oss.'
		},
		{
			type: 'layeredImage',
			height: '28rem',
			images: [
				{
					src: can,
					src2x: can2x,
					alt: 'burk',
					imgStyles: {
						width: '98px',
						height: 'auto',
						top: '20px',
						left: '10px'
					},
					parallaxSpeed: 0.02
				},
				{
					src: phone,
					src2x: phone2x,
					alt: '',
					imgStyles: {
						width: '220px',
						height: 'auto',
						bottom: '-50px',
						right: '0'
					},
					parallaxSpeed: 0.06
				}
			]
		},
		{
			type: 'text',
			paragraph:
				'Jag och Francine sågs igen på kvällen, vi tränar i samma basketlag. Efter träningen hängde jag kvar i omklädningsrummet så inte Francine skulle bli sist ut, hon tar alltid femtusen år på sig. Jag öppnade en energidryck medan hon torkade håret och vi snackade om våra lärare, vilka vi gillade och så. Jag sa att han biologilraren hade sagt en massa saker som inte stämde, t.ex. att hiv inte smittas bara genom samlag. Francine frågade hur jag kunde veta det, det var ju inte direkt jag som var lärare. Jag var tyst några sekunder. Jag hade stått många gånger framför spegeln och övat på olika sätt att säga det. Det här var ett sånt tillfälle jag hade väntat på, jag kunde inte banga nu. Jag sa att jag visste det eftersom jag själv har hiv. Jag blev så nervös!! Vågade knappt kolla på Francines reaktion. Hon frågade om jag berättat för fler och jag skakade på huvudet.'
		},
		{
			type: 'text',
			paragraph:
				'Francine frågade hur det kändes att ha hiv och jag sa att det känns ingenting, men att jag tänker på det då och då.'
		},
		{
			type: 'layeredImage',
			height: '29rem',
			images: [
				{
					src: locker,
					src2x: locker2x,
					alt: 'skåp',
					imgStyles: {
						width: '130px',
						height: '231px',
						top: '0',
						right: '0'
					},
					parallaxSpeed: -0.015
				},
				{
					src: towel,
					src2x: towel2x,
					alt: 'handduk',
					imgStyles: {
						width: '200px',
						height: 'auto',
						bottom: '-45px',
						left: '0'
					},
					parallaxSpeed: 0.01
				}
			]
		},
		{
			type: 'text',
			paragraph:
				'Francine tittade intensivt på mig och sa att hon lovade att hon inte skulle berätta för någon. Att det kunde vara något mellan oss bara. Jag försökte förklara att det inte var någon hemlighet direkt. Hon reagerade typ som om det vore något coolt? Jag ba "äsch, vi släpper det". Hon tog det kanske inte jättebra men det kändes ändå okej. Nu har jag i alla fall testat och är lite förberedd.'
		}
	]
};

export default aranya;
