import React, { ReactElement, useState } from 'react';
import cx from 'classnames';

import StoryContext from '@Src/context/StoryContext';
import SwiperContext from '@Src/context/SwiperContext';
import OverlayContext from '@Src/context/OverlayContext';

import Routes from './Routes';

import styles from './App.module.scss';

const App = (): ReactElement => {
	const [storyIndex, setCurrentStoryIndex] = useState(0);
	const storyValue = { storyIndex, setCurrentStoryIndex };

	const [slideIndex, setCurrentSlideIndex] = useState(0);
	const swiperValue = { slideIndex, setCurrentSlideIndex };

	const [bgColor, setBgColor] = useState('#1caddd');
	const overlayValue = { bgColor, setBgColor };

	return (
		<StoryContext.Provider value={storyValue}>
			<SwiperContext.Provider value={swiperValue}>
				<OverlayContext.Provider value={overlayValue}>
					<div className={cx(styles.app, styles[`app--${slideIndex}`])}>
						<Routes />
					</div>
				</OverlayContext.Provider>
			</SwiperContext.Provider>
		</StoryContext.Provider>
	);
};

export default App;
