import drops from '@Src/assets/karwan/drops.png';
import drops2x from '@Src/assets/karwan/drops2x.png';
import talk from '@Src/assets/karwan/talk.png';
import talk2x from '@Src/assets/karwan/talk2x.png';
import talk2 from '@Src/assets/karwan/talk2.png';
import talk22x from '@Src/assets/karwan/talk22x.png';
import bed from '@Src/assets/karwan/bed.png';
import bed2x from '@Src/assets/karwan/bed2x.png';
import bed2 from '@Src/assets/karwan/bed2.png';
import bed22x from '@Src/assets/karwan/bed22x.png';
import phone from '@Src/assets/karwan/phone.png';
import phone2x from '@Src/assets/karwan/phone2x.png';
import phone2 from '@Src/assets/karwan/phone2.png';
import phone22x from '@Src/assets/karwan/phone22x.png';
import phone3 from '@Src/assets/karwan/phone3.png';
import phone32x from '@Src/assets/karwan/phone32x.png';

const karwan = {
	index: 3,
	id: 'karwan',
	name: 'Karwan',
	age: '21',
	cardImages: [
		{
			src: bed2,
			src2x: bed22x,
			alt: 'säng',
			imgStyles: {
				width: '85%',
				top: '25%',
				left: '7%'
			}
		},
		{
			src: talk,
			src2x: talk2x,
			alt: 'pratbubbla',
			imgStyles: {
				width: '29%',
				top: '12%',
				left: '32%'
			}
		},
		{
			src: drops,
			src2x: drops2x,
			alt: '',
			imgStyles: {
				width: '27%',
				top: '0',
				left: '17%'
			}
		},
		{
			src: phone3,
			src2x: phone32x,
			alt: 'telefon',
			imgStyles: {
				width: '30%',
				bottom: '7%',
				right: '26%'
			}
		}
	],
	heroImages: [
		{
			src: bed,
			src2x: bed2x,
			alt: 'säng',
			imgStyles: {
				width: '65%',
				height: 'auto',
				bottom: '0',
				right: '0'
			},
			parallaxSpeed: -0.55
		},
		{
			src: talk,
			src2x: talk2x,
			alt: 'pratbubbla',
			imgStyles: {
				width: '27%',
				height: 'auto',
				top: '33%',
				left: '20%'
			},
			parallaxSpeed: -0.4
		},
		{
			src: drops,
			src2x: drops2x,
			alt: '',
			imgStyles: {
				width: '32%',
				height: 'auto',
				top: '10px',
				left: '0'
			},
			parallaxSpeed: -0.3
		},
		{
			src: phone,
			src2x: phone2x,
			alt: 'telefon',
			imgStyles: {
				width: '28%',
				height: 'auto',
				top: '20px',
				right: '10px'
			},
			parallaxSpeed: -0.6
		}
	],
	bgColor: '210, 31, 98',
	body: [
		{
			type: 'text',
			paragraph:
				'Min tjej ringde mig och frågade om hon fick komma över, hon lät ledsen på rösten. När hon kom satte vi oss på min säng och jag frågade om det hade hänt något. Hon sa att hennes farmor hade dött i natt, och att hon inte hunnit säga hejdå. Hon sa att hon tänkte på alla i hemlandet som hon saknade och att hon önskade att hon hunnit säga hejdå. Sen började hon gråta. Jag frågade om hon ville ha lite te eller vatten, men hon skakade på huvudet. Jag tittade på tårarna som trillade ner på min kudde, det gjorde ont i kroppen att se henne så ledsen. Hon sa förlåt för att hon bölade så mycket, jag sa att det var okej och kramade henne länge.'
		},
		{
			type: 'layeredImage',
			height: '36rem',
			images: [
				{
					src: phone2,
					src2x: phone22x,
					alt: 'telefon',
					imgStyles: {
						width: '120px',
						height: 'auto',
						bottom: '0',
						right: 'calc(40% - 60px)'
					},
					parallaxSpeed: 0.03
				},
				{
					src: drops,
					src2x: drops2x,
					alt: '',
					imgStyles: {
						width: '156px',
						height: 'auto',
						top: '0',
						left: '0'
					},
					parallaxSpeed: -0.03
				}
			]
		},
		{
			type: 'text',
			paragraph:
				'Efter en stund torkade hon ögonen med sin tröja och sa att hon måste gå till badrummet och fixa sitt smink. Jag såg ingen skillnad men hon sa att hon såg kaos ut. Jag låg kvar på sängen och funderade lite på vad hon sagt. Jag tänkte på om jag skulle berätta för henne, jag hade tänkt några gånger att jag kanske ville det, vi har ju varit ihop i fyra månader och så. Jag har inte berättat för några av mina kompisar. Jag tar ju mediciner varje dag och viruset är omätbart i blodet, så jag måste inte säga att jag har hiv. Men det här kändes annorlunda, för att hon var min tjej antar jag. Jag ville liksom berätta något om mig själv tillbaka.'
		},
		{
			type: 'text',
			paragraph:
				'Jag tänkte på den där gången när jag försökt berätta för mamma att jag var ledsen över saker som hände i skolan, och hon fattade inte alls. Sa bara att jag skulle ignorera det och gå vidare. Mina föräldrar vill helst inte att jag ska berätta. De är rädda att folk ska se ner på oss, släktingar och så. För det är så många ser på det i vårt hemland.'
		},
		{
			type: 'layeredImage',
			height: '38.3rem',
			images: [
				{
					src: talk2,
					src2x: talk22x,
					alt: 'pratbubbla',
					imgStyles: {
						width: '171px',

						height: 'auto',
						top: '-30px',
						left: '0'
					},
					parallaxSpeed: -0.01
				},
				{
					src: bed2,
					src2x: bed22x,
					alt: 'säng',
					imgStyles: {
						width: '268px',
						height: 'auto',
						bottom: '-50px',
						right: '0'
					},

					parallaxSpeed: 0.035
				}
			]
		},
		{
			type: 'text',
			paragraph:
				'När hon kom tillbaka från badrummet la hon sig ner bredvid mig. Hon sa att det kändes skönt att berätta för mig. Jag sa att jag också hade en grej att berätta, jag lät lite nervös på rösten fast jag försökte vara lugn. Hon såg plötsligt lite rädd ut och frågade om jag hade träffat någon annan. "Nej nej" sa jag, "det är inte något sånt". Sen berättade jag att jag har något som heter hiv. Jag frågade om hon visste vad det var och hon nickade. Hon frågade om flera visste och jag sa nej, men att jag ville att hon skulle veta. Jag sa att det inte påverkar vad vi kan göra, att det inte smittar och så. Men att hon kanske ville följa med till sjukhuset någon gång? För att vara hundra på att det inte smittar liksom. Det ville hon. Vi sa inte så mycket mer, hon gav mig en kram och sa att jag var modig som berättade. Det kändes skönt, att vi hade delat med oss av varsin grej.'
		}
	]
};

export default karwan;
