import bus from '@Src/assets/samuel/bus.png';
import bus2x from '@Src/assets/samuel/bus2x.png';
import clock from '@Src/assets/samuel/clock.png';
import clock2x from '@Src/assets/samuel/clock2x.png';
import pills from '@Src/assets/samuel/pills.png';
import pills2x from '@Src/assets/samuel/pills2x.png';
import ball from '@Src/assets/samuel/ball2.png';
import ball2x from '@Src/assets/samuel/ball22x.png';
import busCircle from '@Src/assets/samuel/buscircle.png';
import busCircle2x from '@Src/assets/samuel/buscircle2x.png';

const samuel = {
	index: 1,
	id: 'samuel',
	name: 'Samuel',
	age: '18',
	cardImages: [
		{
			src: ball,
			src2x: ball2x,
			alt: 'fotboll',
			imgStyles: {
				width: '30%',
				height: 'auto',
				bottom: '0',
				right: '0'
			},
			parallaxSpeed: -0.4
		},
		{
			src: pills,
			src2x: pills2x,
			alt: 'medicin',
			imgStyles: {
				width: '57%',
				height: 'auto',
				top: '15%',
				right: '10%'
			},
			parallaxSpeed: -0.5
		},
		{
			src: bus,
			src2x: bus2x,
			alt: 'buss',
			imgStyles: {
				width: '40%',
				height: 'auto',
				top: '4%',
				left: '4%'
			},
			parallaxSpeed: -0.4
		},

		{
			src: clock,
			src2x: clock2x,
			alt: 'klocka',
			imgStyles: {
				width: '42%',
				height: 'auto',
				bottom: '5%',
				left: '5%'
			},
			parallaxSpeed: -0.3
		}
	],
	heroImages: [
		{
			src: bus,
			src2x: bus2x,
			alt: 'buss',
			imgStyles: {
				width: '100px',
				height: 'auto',
				top: '0',
				left: '30px'
			},
			parallaxSpeed: -0.4
		},
		{
			src: clock,
			src2x: clock2x,
			alt: 'klocka',
			imgStyles: {
				width: '110px',
				height: 'auto',
				bottom: '20px',
				left: '60px'
			},
			parallaxSpeed: -0.3
		},
		{
			src: ball,
			src2x: ball2x,
			alt: 'fotboll',
			imgStyles: {
				width: '120px',
				height: 'auto',
				bottom: '-20px',
				right: '30px'
			},
			parallaxSpeed: -0.4
		},
		{
			src: pills,
			src2x: pills2x,
			alt: 'medicin',
			imgStyles: {
				width: '130px',
				height: 'auto',
				top: '8px',
				left: '45%'
			},
			parallaxSpeed: -0.5
		}
	],
	bgColor: '5, 51, 130',
	body: [
		{
			type: 'text',
			paragraph:
				'Jag och Samir var på väg till fotbollsträningen, det var en vardag som vilken som helst. Vi stod och väntade på bussen och drack varsin energidryck. Plötsligt kände jag bara att jag ska berätta, för jag litar på honom. Jag tog den sista klunken ur min burk och sen sa jag det bara rätt ut, "Jag har hiv". Samir kollade på mig som att jag skojade. Sen ba "Är du seriös?". Han frågade om jag kan bli botad och jag sa nej, men att jag tar mediciner. Han nickade och funderade en sekund, sen frågade han vilken tid. Jag fattade inte vad han menade. "Medicinerna", sa han. Jag garvade och sa att jag tar dom på morgonen innan skolan. Han frågar alltid så knäppa saker Samir, också på lektionerna. Vi hoppade på bussen som hade stannat och pratade inte mer om det sen. Nuförtiden brukar han kolla på klockan ibland och fråga om jag kommit ihåg medicinerna.'
		},
		{
			type: 'layeredImage',
			height: '26rem',
			images: [
				{
					src: busCircle,
					src2x: busCircle2x,
					alt: '',
					imgStyles: {
						width: '132px',
						height: '132px',
						top: '0',
						left: '0'
					},
					parallaxSpeed: -0.03
				},
				{
					src: ball,
					src2x: ball2x,
					alt: '',
					imgStyles: {
						width: '176px',
						height: '176px',
						bottom: '0',
						right: '0'
					},
					parallaxSpeed: 0.03
				}
			]
		},
		{
			type: 'text',
			paragraph:
				'Jag hade nog tänkt att han skulle reagera mer, typ fråga nåt sjukt - om jag skulle dö eller så. Men han verkade inte bry sig så mycket. Jag hade laddat upp och förberett en massa svar, men han bara "ok". Efteråt kände jag mig lite tom, typ som att jag blev besviken. Men jag antar att han bryr sig eftersom han påminner mig om medicinerna. Det är ju snällt. Jag tror i alla fall att han fattade varför jag haft alla mina läkarbesök och sånt, han brukade fråga varför jag gick dit så ofta. Innan brukade jag bara säga att jag behöver kolla upp mitt immunförsvar. Rätt ofta glömmer jag bort att jag har hiv helt ärligt, till exempel om jag är i skolan.'
		},
		{
			type: 'layeredImage',
			height: '28rem',
			images: [
				{
					src: clock,
					src2x: clock2x,
					alt: 'klocka',
					imgStyles: {
						width: '132px',
						height: 'auto',
						bottom: '-50px',
						left: '0'
					},
					parallaxSpeed: 0.04
				},
				{
					src: pills,
					src2x: pills2x,
					alt: 'medicin',
					imgStyles: {
						width: '180px',
						height: 'auto',
						top: '0',
						right: '0'
					},
					parallaxSpeed: -0.02
				}
			]
		}
	]
};

export default samuel;
