import pillow from '@Src/assets/faven/pillow.png';
import pillow2x from '@Src/assets/faven/pillow2x.png';
import pills from '@Src/assets/faven/pills.png';
import pills2x from '@Src/assets/faven/pills2x.png';
import pills2 from '@Src/assets/faven/pills2.png';
import pills22x from '@Src/assets/faven/pills22x.png';
import shoes from '@Src/assets/faven/shoes.png';
import shoes2x from '@Src/assets/faven/shoes2x.png';

const faven = {
	index: 0,
	id: 'faven',
	name: 'Faven',
	age: '14',
	cardImages: [
		{
			src: pillow,
			src2x: pillow2x,
			alt: 'kudde',
			imgStyles: {
				width: '80%',
				height: 'auto',
				top: '0',
				left: '7%'
			},
			parallaxSpeed: -0.55
		},
		{
			src: pills2,
			src2x: pills22x,
			alt: 'medicin',
			imgStyles: {
				width: '25%',
				height: 'auto',
				top: '20px',
				right: '7%'
			},
			parallaxSpeed: -0.6
		},
		{
			src: shoes,
			src2x: shoes2x,
			alt: 'skor',
			imgStyles: {
				width: '65%',
				height: 'auto',
				bottom: '3%',
				left: '30px'
			},
			parallaxSpeed: -0.5
		}
	],
	heroImages: [
		{
			src: pillow,
			src2x: pillow2x,
			alt: 'kudde',
			imgStyles: {
				width: '60%',
				height: 'auto',
				top: '0',
				left: '20px'
			},
			parallaxSpeed: -0.55
		},
		{
			src: pills,
			src2x: pills2x,
			alt: 'medicin',
			imgStyles: {
				width: '25%',
				height: 'auto',
				top: '20px',
				right: '20%'
			},
			parallaxSpeed: -0.6
		},
		{
			src: shoes,
			src2x: shoes2x,
			alt: 'skor',
			imgStyles: {
				width: '55%',
				height: 'auto',
				bottom: '9%',
				right: '30px'
			},
			parallaxSpeed: -0.5
		}
	],
	bgColor: '14, 132, 132',
	body: [
		{
			type: 'text',
			paragraph:
				'Det var fredag och jag var på sleepover hos Wendy och Yasse, vi brukar sova över hos varandra ofta. Wendy har jag känt länge typ sju år kanske. Yasse har vi bara hängt med senaste året, men det var ändå en känsla att jag kunde berätta för henne också. Vi satt uppe ganska sent, tills Wendys farsa knackade på och sa åt oss att vi "faktiskt var tvungna att sova". Wendy himlade med ögonen och ba jaja. Så kröp vi ner på våra madrasser och fortsatte visk-prata tills alla somnade. På morgonen vaknade jag först. Jag tog en bit toapapper från min väska och la tabletterna däri, för att det inte skulle låta. När jag reste mig för att smyga upp till badrummet såg jag att Wendy var vaken och låg och kollade på mig från sin säng. Fuck! Hon frågade vad det var för tabletter jag hade, jag tänkte snabbt och sa att det var vitaminer. Hon ba "vilka vitaminer då?" och jag ba "eeh kommer inte ihåg vad dom heter". Och så försökte jag gömma burken under min kudde så att hon inte skulle se etiketten. Wendy stirrade på mig med ett "really?", det var så tydligt att hon inte trodde på mig. Yasse hade också vaknat och frågade trött vad vi snackade om. Wendy kollade fortfarande på mig, hon såg typ besviken ut. Då kände jag bara att fan vad trött jag är på det här!'
		},
		{
			type: 'layeredImage',
			height: '30rem',
			images: [
				{
					src: pills,
					src2x: pills2x,
					alt: 'medicin',
					imgStyles: {
						width: '105px',
						height: 'auto',
						top: '0',
						left: '0'
					},
					parallaxSpeed: -0.02
				},
				{
					src: pillow,
					src2x: pillow2x,
					alt: 'kudde',
					imgStyles: {
						width: '272px',
						height: 'auto',
						bottom: '-20px',
						right: '0'
					},
					parallaxSpeed: 0.025
				}
			]
		},
		{
			type: 'text',
			paragraph:
				'Jag började med att säga att ok det är inte en vitamin utan en medicin, innan jag sa ordet hiv liksom. För när man säger det, då blir det så seriöst. Och Yasse är lite så "drama queen". Så jag tänkte att det är bättre att berätta hur medicinen hjälper mig först och att det inte är något allvarligt. Sen förklarade jag att mitt immunförsvar kan bli dåligt och då hjälper medicinen.'
		},
		{
			type: 'text',
			paragraph:
				'Wendy visste typ mer än jag! Blev i och för sig inte förvånad för hon gillar att plugga och kollar alltid upp massa fakta och så. Yasse verkade lite chockad och ba, "va, har DU hiv?" Jag ba "ja, men alltså det smittas inte". För vi hade ju ändå ätit från samma glass, druckit från samma dricka, sovit hos varandra och så. Så jag fattar att hon blev nojig. Efter att jag förklarat verkade hon mer chill. Wendy såg lite nervös ut och frågade om jag kommer dö. Jag sa "nej det kommer jag inte" och så förklarade jag lite hur medicinen fungerar. Sen sa jag att dom fick svära på att inte säga till några andra, båda nickade och kramade mig.'
		},
		{
			type: 'layeredImage',
			height: '14rem',
			images: [
				{
					src: shoes,
					src2x: shoes2x,
					alt: 'tofflor',
					imgStyles: {
						width: '254px',
						height: 'auto',
						top: '20px',
						left: '0'
					},
					parallaxSpeed: 0.015
				}
			]
		},
		{
			type: 'text',
			paragraph:
				'Det kändes bra men också lite jobbigt att berätta. Dom kan ju inte kan relatera, men det är skönt att slippa ljuga nu. Vi hänger ju liksom varje dag, även när vi inte hänger så ringer vi varandra, snackar om vad vi gjorde idag eller sånt. Dom har inte frågat så mycket mer sen dess. Ibland frågar dom när jag varit på sjukhuset och mina värden gått upp eller ner - då frågar dom varför och vad det kan leda till. Förut brukade jag säga att skulle till tandläkaren eller nåt, men det behöver jag inte nu.'
		}
	]
};

export default faven;
