import home from './home';

import faven from './faven';
import samuel from './samuel';
import aranya from './aranya';
import karwan from './karwan';

import info from './info';
import thoughts from './thoughts';

const content = {
	home,
	stories: [faven, samuel, aranya, karwan],
	info,
	thoughts
};

export default content;
