const info = {
	slug: 'hiv-viktigt',
	heading: 'HIV-viktigt',
	body: [
		{
			type: 'text',
			text: [
				'Hiv är en kronisk (livslång) infektion.',
				'Det finns idag bra mediciner som gör att den som lever med hiv inte blir sjuk. Medicinen hindrar också viruset från att spridas. Det betyder att den som lever med hiv och medicinerar inte kan föra över infektionen till andra människor.',
				'Vita blodkroppar är en del kroppens immunförsvar. Immunförsvaret skyddar kroppen mot olika sjukdomar. När hiv-viruset tar sig in i kroppen, letar den upp en särskild sorts vita blodkroppar som kallas för hjälparceller (CD4 celler). Hiv-viruset använder hjälparcellen för att göra fler hiv-virus (viruset förökar sig). När hjälparcellen har använts förstörs den. Om hiv-viruset förstör många hjälparceller blir immunförsvaret svagt och klarar inte av att skydda kroppen från en mängd olika infektioner och sjukdomar.',
				'Hiv-mediciner skyddar hjälparcellerna. Viruset kan då inte längre föröka sig utan förstörs. Den som har hiv tar medicin varje dag, hela livet. När hjälparcellerna är skyddade av medicin kan de bli fler igen. Då får kroppen ett starkt immunförsvar och kan skydda sig mot andra sjukdomar. Det kommer alltid finnas lite virus kvar i kroppen hos den som lever med hiv. Viruset är mycket svagt, det kan inte göra sig till fler och det kan inte förstöra hjälparcellerna. Viruset "sover" kan en säga. I sjukvården kallas det för att hiv-viruset är omätbart, vilket betyder att det inte går att mäta i blodprov.'
			]
		},
		{
			type: 'list',
			heading: 'Ett långt och friskt liv',
			text: 'Om den som lever med hiv har tillgång till mediciner kan den personen leva ett friskt och långt liv.',
			listItems: [
				'Hen kan ha samlag utan att hiv förs över till andra.',
				'Hen kan skaffa barn, hiv förs inte över till barnet.',
				'Hen har inga begränsningar när det gäller studieval eller yrkesval.'
			]
		},
		{
			type: 'list',
			heading: 'Så kan viruset föras över till andra',
			text:
				'Det kan ta flera år innan en person som har hiv får symtom, dvs blir sjuk. Det enda sättet att ta reda på om en person har hiv är genom att ta ett blodprov. Om en person inte vet om att den har hiv (och då inte heller har mediciner mot hiv) kan viruset föras över till andra på följande sätt:',
			listItems: [
				'Från en mamma till ett barn, under slutet av graviditet, under förlossning eller via bröstmjölk vid amning.',
				'Via samlag.',
				'Via sprutor som används mellan flera människor (i sjukvården i Sverige används alltid engångssprutor som slängs efter varje gång de använts) och via blodprodukter som inte är noggrant kontrollerade (i Sverige är blodprodukter noggrant kontrollerade).'
			]
		},
		{
			type: 'text',
			text: [
				'I de allra flesta länder i världen finns det hiv-mediciner. I vissa delar av världen finns det inte, det kan bero på krig, fattigdom, brist på sjukvård m.m.'
			]
		}
	]
};

export default info;
