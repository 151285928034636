const thoughts = {
	slug: 'funderingar',
	heading: 'Funderingar',
	subHeading: 'Möjligheten & rätten till att vara sig själv',
	text: [
		'Att berätta om något personligt kan ofta kännas som en lättnad, göra det lättare att prata om andra saker och leda till att du och dina vänner kommer varandra närmare. Du kan förbereda dig för hur du vill berätta personliga saker på olika sätt, till exempel för ett syskon vid middagsbordet, en vän på stranden eller din partner framför en film. Det är alltid upp till dig själv att bestämma vad du vill berätta och för vem. Hiv är bara en del av en persons liv och som individ är du så mycket mer än summan av alla dina delar. Du är unik och när du utgår från det som känns bra för dig gör du rätt.'
	],
	questions: [
		{
			heading: 'Att berätta för andra',
			text: [
				'Att berätta att en lever med hiv behöver inte vara svårt. Huruvida någon vill berätta är upp till personen själv.  Om du funderar på att berätta, kan du börja med att tänka på anledningen till att du vill berätta. Du kan också fråga dig vem (eller vilka) du vill berätta för och på vilket sätt du vill göra det.',
				'Om du är ovan att prata om hiv men inte vet hur du ska ta upp det kan det finnas flera olika sätt att förbereda en avslappnad samtalssituation. Kanske vill du förbereda ett tillfälle där det finns gott om tid att prata ostört (utan att ha bråttom till annat) eller bara ta upp det spontant. Fundera på vad som får dig att känna dig bekväm.'
			]
		},
		{
			heading: 'Att berätta för en partner',
			text: [
				'Att vara öppen med sin hivstatus för sin partner kan vara stärkande för relationen och kännas skönt. Det är alltid upp till dig själv att välja om du vill berätta eller inte.',
				'Om du vill berätta för din partner så finns det olika strategier att använda. Exempelvis kan du ta med din partner till din läkare, sjuksköterska och kurator som kan stödja dig i samtalet. Ett annat sätt är att kontakta Noaks Ark Stockholm eller andra föreningar som erbjuder stödsamtal.'
			],
			links: [
				{
					label: 'Noaks Ark Stockholm',
					url: 'https://noaksark.org/stockholm'
				},
				{
					label: 'Övriga Noaks Ark föreningar',
					url: 'https://noaksark.org/om-oss/organisation/foreningar/'
				}
			]
		},
		{
			heading: 'Att berätta för familjen',
			text: [
				'En del familjer pratar öppet om hiv medan andra inte gör det och det finns olika anledningar till det. Om du känner att det är viktigt att prata mer med dina familjemedlemmar kan du välja en situation där du känner dig bekväm och berätta att du skulle vilja att ni pratade mer om hur det är för dig att leva med hiv.',
				'Om du vill prata om hiv i din familj och vill ha stöd i hur du kan göra det, så kan du ta hjälp av sjuksköterska/kurator på mottagningen där du följs upp för hiv. För rådgivning kan du även kontakta Barnhivcentrum på Astrid Lindgrens sjukhus, Noaks Ark Stockholm eller andra föreningar som erbjuder stödsamtal.'
			],
			links: [
				{
					label: 'Barnhivcentrum',
					url: 'http://barnhiv.se/vad-ar-hiv/fragor-svar/'
				},
				{
					label: 'Noaks Ark Stockholm',
					url: 'https://noaksark.org/stockholm'
				},
				{
					label: 'Övriga Noaks Ark föreningar',
					url: 'https://noaksark.org/om-oss/organisation/foreningar/'
				}
			]
		},
		{
			heading: 'Att berätta för vänner',
			text: [
				'När du pratar om hiv kan det väcka olika frågor hos dina vänner. Många vet inte vad hiv är och saknar kunskap och åsikter i ämnet, vilket kan göra dem mer mottagliga för fakta. Exempelvis vet många inte att behandlad hiv inte smittar vid sex, eftersom dagens mediciner är så effektiva. Att dela med dig av något personligt med en vän kan kännas som en lättnad och kan bidra till att er vänskap utvecklas.',
				'Här finns bra fakta om hiv:'
			],
			links: [
				{
					label: 'U=U',
					url: 'https://noaksark.org/stockholm/uu/'
				},
				{
					label: 'Barnhivcentrum',
					url: 'http://barnhiv.se/vad-ar-hiv/fragor-svar/'
				},
				{
					label: 'Noaks Ark Stockholm',
					url: 'https://noaksark.org/stockholm'
				},
				{
					label: 'Övriga Noaks Ark föreningar',
					url: 'https://noaksark.org/om-oss/organisation/foreningar/'
				}
			]
		}
	],
	linkSection: {
		heading: 'Här hittar du bra länkar till mer fakta om hiv',
		links: [
			{
				label: 'Barnhivcentrum',
				href: 'http://barnhiv.se/'
			},
			{
				label: 'Noaks Ark Stockholm',
				href: 'https://noaksark.org/'
			},
			{
				label: 'Övriga Noaks Ark föreningar',
				href: 'https://noaksark.org/om-oss/organisation/foreningar/'
			}
		]
	}
};

export default thoughts;
